// ShippingInstructionDetail / productassign
import {
  Controller
} from "@hotwired/stimulus";

export default class extends Controller {
  static values = {orderedquantity: Number, status: String}
  static targets = ["shipselect"];

  connect() {
    this.saveButtonDisabled()
    this.hideOtherStatus()
  }

  hideOtherStatus(){
    document.querySelectorAll('.js-select-shippable-product-row').forEach(element  => {
      // statusと違う・もしくは選択されていなければhideする
      console.log("this.statusValue:" + this.statusValue);
      if(this.statusValue === 'sound' && element.dataset.status === 'sample'){
        // サウンド受注時についてサンプル商品も表示する
        element.style.display = ''; 
      }else if(element.dataset.status === this.statusValue || element.dataset.checked === "true"){
        element.style.display = ''; 
      }else{
        element.style.display = 'none';         
        // 全ステータス表示ボタン、デフォルトではdisplay: noneなのをひとつでもあれば表示する
        document.getElementById("js-show-all-button").style.display = ''
      }
    })   
  }

  showAllStatus(){
    document.querySelectorAll('.js-select-shippable-product-row').forEach(element => {
      element.style.display = ''; 
    })   
  }


  total_count(){
    let sum = 0;
    this.shipselectTargets.forEach(checkbox => {
      if(checkbox.checked){
        // console.log("checkbox.dataset.quantity: " + checkbox.dataset.quantity)
        sum += Number(checkbox.dataset.quantity)
      }
    })
    return sum
  }

  saveButtonDisabled(){
    document.getElementById("shippable_products_save_button").disabled = "disabled"
  }

  saveButtonEnabled(){
    document.getElementById("shippable_products_save_button").disabled = null
  }

  // 数のチェック
  change(){
    let sum = this.total_count()
    console.log("sum: " + sum)
    if(sum < this.orderedquantityValue){
      this.saveButtonDisabled()

      document.getElementById("shippable_products_warning_area").classList.remove("invisible")
      document.getElementById("shippable_products_warning_area").textContent = "出荷数が足りません"

    }else{
      document.getElementById("shippable_products_warning_area").classList.add("invisible")
      this.saveButtonEnabled()
    }
  }
}