import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // 各フォームの表示状態を切り替える
  toggleForms() {
    event.preventDefault();
    document.querySelectorAll('.toggled-item, .reverse-toggled-item').forEach(form => {
      // 現在の表示状態に応じて切り替え
      const currentDisplay = window.getComputedStyle(form).display;
      if (currentDisplay === "none") {
        form.style.display = "block"; // 非表示から表示に
      } else {
        form.style.display = "none"; // 表示から非表示に
      }
    });
  }

  clearForm() {
    event.preventDefault();
    const textArea = document.getElementById('delivery_slip_note_detail');
    if (textArea) {
      textArea.value = ''; // テキストエリアの内容を消去
    }

    // 他のフォーム要素があれば、同様にクリアする処理を追加
    // 例: input, select など
    const inputs = this.element.querySelectorAll('input, select');
    inputs.forEach(input => {
      input.value = ''; // 各入力要素の内容を消去
    });
    // フォームを隠す
    this.toggleForms();
  }
}