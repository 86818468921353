import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // ステータスを取得する
    const status = document.getElementById('form_search_bcart_order_form_status').value;
    this.toggledCheckbox(status);
  }

  // TODO: 受注一覧画面の詳細検索において、対象レコードが多い場合にパフォーマンスが低下する問題の一次対処として、レコード数の多いステータスの場合に詳細検索を利用できないようにする
  // TODO: 本対応は一時的なものであり、将来的にはフラグをDBに直接保存するなどのの見直しを行う予定
  changedStatus(event) {
    this.toggledCheckbox(event.target.value);
  }

  toggledCheckbox(status) {
    document.querySelectorAll('.toggled-checkbox').forEach(checkbox => {
      if (status === "完了" || status === "") {
        // チェックボックスを非活性にし、選択されていた場合は解除する
        checkbox.disabled = true;
        checkbox.checked = false;
      } else {
        // チェックボックスを活性にする
        checkbox.disabled = false;
      }
    });
  }
}